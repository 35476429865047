<template>
  <div class="content">
    <div class="bannerView" v-if="swiperList.length > 0">
      <swiper :options="swiperOptionOne" ref="mySwiperOne">
        <swiper-slide v-for="(item, index) in swiperList" :key="index">
          <div class="swiperItem">
            <template v-if="item.is_video == 0">
              <img :src="item.attachment_url" />
              <router-link
                :to="`/product_details?id=${item.id}`"
                class="link"
                >{{ $t("localization.home.more_details") }}</router-link
              >
            </template>
            <template v-else>
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="false"
                :options="videoPlayerOptionsFun(item)"
              ></video-player>
            </template>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>
    <div class="main">
      <!--  rpa入口 zd 20220811    -->
      <section class="sixView">
        <div class="imgView" @click="toRpa">
          <img :src="rpaEntranceBanner.pic" alt="" />
        </div>
        <div class="imgView" @click="toNews">
          <img :src="rpaEntranceRightBanner.pic" alt="" />
        </div>
      </section>
      <section class="oneView">
        <div class="screen_view_wrap">
          <screen-view @change="change_screen" test />
        </div>
        <div class="rowView">
          <el-row style="width: 100%" v-if="oneList.length != 0">
            <el-col
              class="colView"
              :span="4"
              v-for="(item, index) of oneList"
              :key="index"
            >
              <product-item :item="item" img-key-name="attachment_url" />
            </el-col>
          </el-row>
          <empty-data icon-size="60px" font-size="16px" v-else />
          <router-link class="more" to="/product">{{
            $t("localization.home.all_products")
          }}</router-link>
        </div>
      </section>
      <section class="twoView">
        <div class="swiperView" v-if="twoList.length > 0">
          <swiper :options="swiperOptionTwo" ref="mySwiperOne">
            <swiper-slide
              class="swiperItem"
              v-for="(item, index) in twoList"
              :key="index"
            >
              <div class="txtView">
                <h3 class="NotoSansTCBold">{{ item.title }}</h3>
                <p>{{ item.description }}</p>
              </div>
              <div class="imgView">
                <img :src="item.attachment_url" alt="" />
              </div>
            </swiper-slide>
            <div class="swiper-button-prev swiper_btn" slot="button-prev">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="swiper-button-next swiper_btn" slot="button-next">
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </section>
      <section class="threeView">
        <el-row :gutter="4">
          <el-col :span="6" v-for="(item, index) in threeList" :key="index">
            <router-link :to="`/${item.link}`" class="link">
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <h4 class="NotoSansTCBold">{{ item.title }}</h4>
                <p>{{ item.description }}</p>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </section>
      <choice-view />
      <section class="fiveView">
        <el-row class="fiveRow" :gutter="22">
          <el-col :span="8" v-for="(item, index) in fiveList" :key="index">
            <router-link
              :to="`/knowledge_base_detailed?id=${item.id}&pid=${item.f_cate_id}&twoId=${item.cate_id}`"
              class="link"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <span>{{ item.create_time }}</span>
                <p>{{ item.title }}</p>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </section>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import screenView from "@/components/screen";
import productItem from "@/components/product-item";
import choiceView from "@/components/module/choice";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    screenView,
    productItem,
    choiceView,
    videoPlayer,
  },
  data() {
    return {
      swiperOptionOne: {
        loop: false,
        autoplay: {
          delay: 8000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        preventClicks: false,
      },
      swiperOptionTwo: {
        loop: true,
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      videoPlayerOptions: {},

      swiperList: [],
      oneList: [],
      twoList: [],
      threeList: [],
      fiveList: [],

      is_index: 1, //是否首页,1是0否
      sample_id: 0, //样品id
      standard_id: 0, //标准id
      test_id: 0, //测试id
      rpaEntranceBanner: '', //rpa首页入口图
      rpaEntranceRightBanner: ''  //rpa首页入口图右侧图
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperOne.swiper;
    },
  },
  mounted() {
    this.getData();
    this.getProductData();
    this.getEntrance();
  },
  methods: {
    /**
     * 筛选回调
     */
    change_screen(e) {
      this.sample_id = e.sample;
      this.standard_id = e.standard;
      this.test_id = e.test;
      this.getProductData();
    },
    /**
     * 获取产品
     */
    getProductData() {
      this.$http
        .product({
          is_index: this.is_index,
          sample_id: this.sample_id,
          standard_id: this.standard_id,
          test_id: this.test_id,
          limit: 6,
          page: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.oneList = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    /**
     * 处理数据
     */
    handle_index_nav(arr) {
      let arrData = [];
      arr.map((item, index) => {
        let link = "";
        if (index == 0) {
          link = "about";
        }
        if (index == 1) {
          link = "calibration_and_maintenance";
        }
        if (index == 2) {
          link = "online_teaching";
        }
        if (index == 3) {
          link = "knowledge_base";
        }
        arrData.push({
          ...item,
          link,
        });
      });
      return arrData;
    },
    getData() {
      const vm = this;
      this.$http.home().then((res) => {
        if (res.code == 200) {
          this.swiperList = res.data.swiper;
          // this.oneList = res.data.one;
          this.twoList = res.data.application;
          this.threeList = vm.handle_index_nav(res.data.index_nav);
          this.fiveList = res.data.index_knowledge;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**banner视频 */
    videoPlayerOptionsFun(item) {
      const videoPlayerOptions = {
        playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], //播放速度
        autoplay: true, // 是否自动播放。
        muted: true, // 是否静音播放，默认情况下将会消除任何音频。
        loop: true, // 是否循环播放。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "12:5", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 是否流体从而按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: item.video_url, // 视频url地址
          },
        ],
        poster: item.pic, // 封面地址
        width: "100%",
        notSupportedMessage: "此视频暂无法播放...", // 当无法播放时允许覆盖Video.js，显示的默认信息。
        controlBar: {
          timeDivider: false, //当前时间和持续时间的分隔符
          durationDisplay: false, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: false, //全屏按钮
        },
      };
      return videoPlayerOptions;
    },
    //rpa首页产品页入口图 zd 20220811
    getEntrance(){
      this.$http.getRpaEntranceBanner().then((res) => {
        if (res.code == 200) {
          this.rpaEntranceBanner = res.data.index;
          this.rpaEntranceRightBanner = res.data.index_right;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toRpa(){
      this.$router.push({
        path: "/rpa-ultra"
      });
    },
    toNews(){
      this.$router.push({
        path: "/news_details",
        query: {
          id: this.rpaEntranceRightBanner.rpa_right_news_id
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/home.scss";
/deep/ .vjs-control-bar {
  display: none;
}
</style>